/* Autogenerated file. Do not edit manually. */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */

/*
  Fuels version: 0.94.8
*/

import { Contract, Interface } from "fuels";
import type {
  Provider,
  Account,
  StorageSlot,
  AbstractAddress,
  BigNumberish,
  BN,
  FunctionFragment,
  InvokeFunction,
} from 'fuels';

import type { Option, Enum } from "./common";

export enum AccessErrorInput { CannotReinitialized = 'CannotReinitialized', NotOwner = 'NotOwner' };
export enum AccessErrorOutput { CannotReinitialized = 'CannotReinitialized', NotOwner = 'NotOwner' };
export type IdentityInput = Enum<{ Address: AddressInput, ContractId: ContractIdInput }>;
export type IdentityOutput = Enum<{ Address: AddressOutput, ContractId: ContractIdOutput }>;
export enum SideInput { Buy = 'Buy', Sell = 'Sell' };
export enum SideOutput { Buy = 'Buy', Sell = 'Sell' };
export enum ThunderExchangeErrorsInput { OnlyOwner = 'OnlyOwner', Initialized = 'Initialized', AmountHigherThanPoolBalance = 'AmountHigherThanPoolBalance', AssetIdNotMatched = 'AssetIdNotMatched', AmountNotMatched = 'AmountNotMatched', StrategyMustBeNonZeroContract = 'StrategyMustBeNonZeroContract', StrategyNotWhitelisted = 'StrategyNotWhitelisted', MakerMustBeNonZeroAddress = 'MakerMustBeNonZeroAddress', TakerMustBeNonZeroAddress = 'TakerMustBeNonZeroAddress', CallerMustBeMaker = 'CallerMustBeMaker', ExpirationRangeOutOfBound = 'ExpirationRangeOutOfBound', NonceMustBeNonZero = 'NonceMustBeNonZero', PriceMustBeNonZero = 'PriceMustBeNonZero', AmountMustBeNonZero = 'AmountMustBeNonZero', AssetNotSupported = 'AssetNotSupported', ExecutionInvalid = 'ExecutionInvalid', PaymentAssetMismatched = 'PaymentAssetMismatched', PriceMismatched = 'PriceMismatched', AmountMismatched = 'AmountMismatched', PoolTransferFromFailed = 'PoolTransferFromFailed', PoolMismatchedAssetBalance = 'PoolMismatchedAssetBalance' };
export enum ThunderExchangeErrorsOutput { OnlyOwner = 'OnlyOwner', Initialized = 'Initialized', AmountHigherThanPoolBalance = 'AmountHigherThanPoolBalance', AssetIdNotMatched = 'AssetIdNotMatched', AmountNotMatched = 'AmountNotMatched', StrategyMustBeNonZeroContract = 'StrategyMustBeNonZeroContract', StrategyNotWhitelisted = 'StrategyNotWhitelisted', MakerMustBeNonZeroAddress = 'MakerMustBeNonZeroAddress', TakerMustBeNonZeroAddress = 'TakerMustBeNonZeroAddress', CallerMustBeMaker = 'CallerMustBeMaker', ExpirationRangeOutOfBound = 'ExpirationRangeOutOfBound', NonceMustBeNonZero = 'NonceMustBeNonZero', PriceMustBeNonZero = 'PriceMustBeNonZero', AmountMustBeNonZero = 'AmountMustBeNonZero', AssetNotSupported = 'AssetNotSupported', ExecutionInvalid = 'ExecutionInvalid', PaymentAssetMismatched = 'PaymentAssetMismatched', PriceMismatched = 'PriceMismatched', AmountMismatched = 'AmountMismatched', PoolTransferFromFailed = 'PoolTransferFromFailed', PoolMismatchedAssetBalance = 'PoolMismatchedAssetBalance' };

export type AddressInput = { bits: string };
export type AddressOutput = AddressInput;
export type AssetIdInput = { bits: string };
export type AssetIdOutput = AssetIdInput;
export type ContractIdInput = { bits: string };
export type ContractIdOutput = ContractIdInput;
export type ExtraParamsInput = { extra_address_param: AddressInput, extra_contract_param: ContractIdInput, extra_u64_param: BigNumberish };
export type ExtraParamsOutput = { extra_address_param: AddressOutput, extra_contract_param: ContractIdOutput, extra_u64_param: BN };
export type MakerOrderInput = { side: SideInput, maker: AddressInput, collection: ContractIdInput, token_id: string, price: BigNumberish, amount: BigNumberish, nonce: BigNumberish, strategy: ContractIdInput, payment_asset: AssetIdInput, extra_params: ExtraParamsInput };
export type MakerOrderOutput = { side: SideOutput, maker: AddressOutput, collection: ContractIdOutput, token_id: string, price: BN, amount: BN, nonce: BN, strategy: ContractIdOutput, payment_asset: AssetIdOutput, extra_params: ExtraParamsOutput };
export type OrderCanceledInput = { user: AddressInput, strategy: ContractIdInput, side: SideInput, nonce: BigNumberish };
export type OrderCanceledOutput = { user: AddressOutput, strategy: ContractIdOutput, side: SideOutput, nonce: BN };
export type OrderExecutedInput = { order: TakerOrderInput };
export type OrderExecutedOutput = { order: TakerOrderOutput };
export type OrderPlacedInput = { order: MakerOrderInput };
export type OrderPlacedOutput = { order: MakerOrderOutput };
export type OrderUpdatedInput = { order: MakerOrderInput };
export type OrderUpdatedOutput = { order: MakerOrderOutput };
export type OwnershipRenouncedInput = { previous_owner: IdentityInput };
export type OwnershipRenouncedOutput = { previous_owner: IdentityOutput };
export type OwnershipSetInput = { new_owner: IdentityInput };
export type OwnershipSetOutput = { new_owner: IdentityOutput };
export type OwnershipTransferredInput = { new_owner: IdentityInput, previous_owner: IdentityInput };
export type OwnershipTransferredOutput = { new_owner: IdentityOutput, previous_owner: IdentityOutput };
export type TakerOrderInput = { side: SideInput, taker: AddressInput, maker: AddressInput, nonce: BigNumberish, price: BigNumberish, token_id: string, collection: ContractIdInput, strategy: ContractIdInput, extra_params: ExtraParamsInput };
export type TakerOrderOutput = { side: SideOutput, taker: AddressOutput, maker: AddressOutput, nonce: BN, price: BN, token_id: string, collection: ContractIdOutput, strategy: ContractIdOutput, extra_params: ExtraParamsOutput };

const abi = {
  "programType": "contract",
  "specVersion": "1",
  "encodingVersion": "1",
  "concreteTypes": [
    {
      "type": "()",
      "concreteTypeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
    },
    {
      "type": "enum errors::ThunderExchangeErrors",
      "concreteTypeId": "479f19c2e6732584a8d67211f59a9c7d1dafb383b714ba8b2343eb079a6d2c88",
      "metadataTypeId": 1
    },
    {
      "type": "enum libraries::order_types::Side",
      "concreteTypeId": "b003c2b57c46049375f998db2f982f6f1598f54310c64f499aab5c7a8ea9a197",
      "metadataTypeId": 2
    },
    {
      "type": "enum libraries::ownable::AccessError",
      "concreteTypeId": "b125b3c353d0e20ba2296785cc1653908f3c1fb7e67e055938c31fd0e1bb8086",
      "metadataTypeId": 3
    },
    {
      "type": "enum std::identity::Identity",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "metadataTypeId": 4
    },
    {
      "type": "enum std::option::Option<enum std::identity::Identity>",
      "concreteTypeId": "253aea1197e8005518365bd24c8bc31f73a434fac0f7350e57696edfdd4850c2",
      "metadataTypeId": 5,
      "typeArguments": [
        "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
      ]
    },
    {
      "type": "struct events::OrderCanceled",
      "concreteTypeId": "bcea1a1c8196adb658b7ceb1b892a1157b6ae379859a52b9fe73717365963e9f",
      "metadataTypeId": 7
    },
    {
      "type": "struct events::OrderExecuted",
      "concreteTypeId": "ea9db4a91e6c7a4c33e788293f3268423e8afe5fa792a0ab21958b5d50200a69",
      "metadataTypeId": 8
    },
    {
      "type": "struct events::OrderPlaced",
      "concreteTypeId": "c0d70b027d51f4624095c4ef483e1132f760e5029a78be67063e7f33181eac28",
      "metadataTypeId": 9
    },
    {
      "type": "struct events::OrderUpdated",
      "concreteTypeId": "47073b9816d9412279107bc0d6bc422cd745629e93d4e1c67c526d7ad5f5b84d",
      "metadataTypeId": 10
    },
    {
      "type": "struct libraries::order_types::MakerOrder",
      "concreteTypeId": "3d46176b340e1859842235481ab83a21dd6493b73dcbfc8e8c0ba96dbd2d07bb",
      "metadataTypeId": 12
    },
    {
      "type": "struct libraries::order_types::TakerOrder",
      "concreteTypeId": "d3408708920d172bcfc4781923ab1553134bdc5d085dcac5537d09287a8847ed",
      "metadataTypeId": 13
    },
    {
      "type": "struct libraries::ownable::OwnershipRenounced",
      "concreteTypeId": "6dd047dcef1fafba87b54170a1ee4a99a8ae37746e594cbc548c5315368b20c3",
      "metadataTypeId": 14
    },
    {
      "type": "struct libraries::ownable::OwnershipSet",
      "concreteTypeId": "8416605cb05f0e41791636365d6644426eb8723c9bbdfc940b41652fc5bb7e61",
      "metadataTypeId": 15
    },
    {
      "type": "struct libraries::ownable::OwnershipTransferred",
      "concreteTypeId": "947c52bd18fcf648398d82d4d7b3cb539ef96fec3211f41a6e733a8a4f02f67e",
      "metadataTypeId": 16
    },
    {
      "type": "struct std::contract_id::ContractId",
      "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "metadataTypeId": 19
    },
    {
      "type": "u64",
      "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
    }
  ],
  "metadataTypes": [
    {
      "type": "b256",
      "metadataTypeId": 0
    },
    {
      "type": "enum errors::ThunderExchangeErrors",
      "metadataTypeId": 1,
      "components": [
        {
          "name": "OnlyOwner",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "Initialized",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "AmountHigherThanPoolBalance",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "AssetIdNotMatched",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "AmountNotMatched",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "StrategyMustBeNonZeroContract",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "StrategyNotWhitelisted",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "MakerMustBeNonZeroAddress",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "TakerMustBeNonZeroAddress",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "CallerMustBeMaker",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "ExpirationRangeOutOfBound",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "NonceMustBeNonZero",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "PriceMustBeNonZero",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "AmountMustBeNonZero",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "AssetNotSupported",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "ExecutionInvalid",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "PaymentAssetMismatched",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "PriceMismatched",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "AmountMismatched",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "PoolTransferFromFailed",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "PoolMismatchedAssetBalance",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        }
      ]
    },
    {
      "type": "enum libraries::order_types::Side",
      "metadataTypeId": 2,
      "components": [
        {
          "name": "Buy",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "Sell",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        }
      ]
    },
    {
      "type": "enum libraries::ownable::AccessError",
      "metadataTypeId": 3,
      "components": [
        {
          "name": "CannotReinitialized",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "NotOwner",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        }
      ]
    },
    {
      "type": "enum std::identity::Identity",
      "metadataTypeId": 4,
      "components": [
        {
          "name": "Address",
          "typeId": 17
        },
        {
          "name": "ContractId",
          "typeId": 19
        }
      ]
    },
    {
      "type": "enum std::option::Option",
      "metadataTypeId": 5,
      "components": [
        {
          "name": "None",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "Some",
          "typeId": 6
        }
      ],
      "typeParameters": [
        6
      ]
    },
    {
      "type": "generic T",
      "metadataTypeId": 6
    },
    {
      "type": "struct events::OrderCanceled",
      "metadataTypeId": 7,
      "components": [
        {
          "name": "user",
          "typeId": 17
        },
        {
          "name": "strategy",
          "typeId": 19
        },
        {
          "name": "side",
          "typeId": 2
        },
        {
          "name": "nonce",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct events::OrderExecuted",
      "metadataTypeId": 8,
      "components": [
        {
          "name": "order",
          "typeId": 13
        }
      ]
    },
    {
      "type": "struct events::OrderPlaced",
      "metadataTypeId": 9,
      "components": [
        {
          "name": "order",
          "typeId": 12
        }
      ]
    },
    {
      "type": "struct events::OrderUpdated",
      "metadataTypeId": 10,
      "components": [
        {
          "name": "order",
          "typeId": 12
        }
      ]
    },
    {
      "type": "struct libraries::order_types::ExtraParams",
      "metadataTypeId": 11,
      "components": [
        {
          "name": "extra_address_param",
          "typeId": 17
        },
        {
          "name": "extra_contract_param",
          "typeId": 19
        },
        {
          "name": "extra_u64_param",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct libraries::order_types::MakerOrder",
      "metadataTypeId": 12,
      "components": [
        {
          "name": "side",
          "typeId": 2
        },
        {
          "name": "maker",
          "typeId": 17
        },
        {
          "name": "collection",
          "typeId": 19
        },
        {
          "name": "token_id",
          "typeId": 0
        },
        {
          "name": "price",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "amount",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "nonce",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "strategy",
          "typeId": 19
        },
        {
          "name": "payment_asset",
          "typeId": 18
        },
        {
          "name": "extra_params",
          "typeId": 11
        }
      ]
    },
    {
      "type": "struct libraries::order_types::TakerOrder",
      "metadataTypeId": 13,
      "components": [
        {
          "name": "side",
          "typeId": 2
        },
        {
          "name": "taker",
          "typeId": 17
        },
        {
          "name": "maker",
          "typeId": 17
        },
        {
          "name": "nonce",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "price",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "token_id",
          "typeId": 0
        },
        {
          "name": "collection",
          "typeId": 19
        },
        {
          "name": "strategy",
          "typeId": 19
        },
        {
          "name": "extra_params",
          "typeId": 11
        }
      ]
    },
    {
      "type": "struct libraries::ownable::OwnershipRenounced",
      "metadataTypeId": 14,
      "components": [
        {
          "name": "previous_owner",
          "typeId": 4
        }
      ]
    },
    {
      "type": "struct libraries::ownable::OwnershipSet",
      "metadataTypeId": 15,
      "components": [
        {
          "name": "new_owner",
          "typeId": 4
        }
      ]
    },
    {
      "type": "struct libraries::ownable::OwnershipTransferred",
      "metadataTypeId": 16,
      "components": [
        {
          "name": "new_owner",
          "typeId": 4
        },
        {
          "name": "previous_owner",
          "typeId": 4
        }
      ]
    },
    {
      "type": "struct std::address::Address",
      "metadataTypeId": 17,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::asset_id::AssetId",
      "metadataTypeId": 18,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::contract_id::ContractId",
      "metadataTypeId": 19,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    }
  ],
  "functions": [
    {
      "inputs": [
        {
          "name": "strategy",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "nonce",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "side",
          "concreteTypeId": "b003c2b57c46049375f998db2f982f6f1598f54310c64f499aab5c7a8ea9a197"
        }
      ],
      "name": "cancel_order",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " Cancels MakerOrder"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "order",
          "concreteTypeId": "d3408708920d172bcfc4781923ab1553134bdc5d085dcac5537d09287a8847ed"
        }
      ],
      "name": "execute_order",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " Executes order by either"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " filling the sell MakerOrder (e.g. purchasing NFT)"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " or the buy MakerOrder (e.g. accepting an offer)"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_asset_manager",
      "output": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_execution_manager",
      "output": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_pool",
      "output": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " Getters ///"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_protocol_fee_recipient",
      "output": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_royalty_manager",
      "output": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_transfer_selector",
      "output": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "initialize",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " Initializes the contract and sets the owner"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "owner",
      "output": "253aea1197e8005518365bd24c8bc31f73a434fac0f7350e57696edfdd4850c2",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " Ownable ///"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "order",
          "concreteTypeId": "3d46176b340e1859842235481ab83a21dd6493b73dcbfc8e8c0ba96dbd2d07bb"
        }
      ],
      "name": "place_order",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " Places MakerOrder by calling the strategy contract"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " Checks if the order is valid"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    },
    {
      "inputs": [],
      "name": "renounce_ownership",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "asset_manager",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        }
      ],
      "name": "set_asset_manager",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "execution_manager",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        }
      ],
      "name": "set_execution_manager",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "pool",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        }
      ],
      "name": "set_pool",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " Setters ///"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "protocol_fee_recipient",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "set_protocol_fee_recipient",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "royalty_manager",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        }
      ],
      "name": "set_royalty_manager",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "transfer_selector",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        }
      ],
      "name": "set_transfer_selector",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "new_owner",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "transfer_ownership",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "order",
          "concreteTypeId": "3d46176b340e1859842235481ab83a21dd6493b73dcbfc8e8c0ba96dbd2d07bb"
        }
      ],
      "name": "update_order",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " Updates the existing MakerOrder"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    }
  ],
  "loggedTypes": [
    {
      "logId": "5160872022870533508",
      "concreteTypeId": "479f19c2e6732584a8d67211f59a9c7d1dafb383b714ba8b2343eb079a6d2c88"
    },
    {
      "logId": "13612721533416287670",
      "concreteTypeId": "bcea1a1c8196adb658b7ceb1b892a1157b6ae379859a52b9fe73717365963e9f"
    },
    {
      "logId": "16905867214671608396",
      "concreteTypeId": "ea9db4a91e6c7a4c33e788293f3268423e8afe5fa792a0ab21958b5d50200a69"
    },
    {
      "logId": "12764806370357731851",
      "concreteTypeId": "b125b3c353d0e20ba2296785cc1653908f3c1fb7e67e055938c31fd0e1bb8086"
    },
    {
      "logId": "9517900813706399297",
      "concreteTypeId": "8416605cb05f0e41791636365d6644426eb8723c9bbdfc940b41652fc5bb7e61"
    },
    {
      "logId": "13895587280595317858",
      "concreteTypeId": "c0d70b027d51f4624095c4ef483e1132f760e5029a78be67063e7f33181eac28"
    },
    {
      "logId": "7912903559520169914",
      "concreteTypeId": "6dd047dcef1fafba87b54170a1ee4a99a8ae37746e594cbc548c5315368b20c3"
    },
    {
      "logId": "10699517786846983752",
      "concreteTypeId": "947c52bd18fcf648398d82d4d7b3cb539ef96fec3211f41a6e733a8a4f02f67e"
    },
    {
      "logId": "5118125025934262562",
      "concreteTypeId": "47073b9816d9412279107bc0d6bc422cd745629e93d4e1c67c526d7ad5f5b84d"
    }
  ],
  "messagesTypes": [],
  "configurables": []
};

const storageSlots: StorageSlot[] = [
  {
    "key": "1d63cc2495bbf5570c9a6d7f632018dc033107e7f4452405c44601bb771a4a5d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "1d63cc2495bbf5570c9a6d7f632018dc033107e7f4452405c44601bb771a4a5e",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "2d976c3fdac11d66a18f3c5395f81971073355420b3aa4dde6b14fe358c814c7",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "2d976c3fdac11d66a18f3c5395f81971073355420b3aa4dde6b14fe358c814c8",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "38a1b038206f348ec947ba5770c6d713148c9d8bb0b312f47acc88777da1c09d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "7adcd318160f9489021b6c5a736557812115bf107f4fc9670a866731f49ed102",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "7adcd318160f9489021b6c5a736557812115bf107f4fc9670a866731f49ed103",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "90d5ec13c7226d2e9e3ba96b754607cdd272673c42ba65f3bc3c20befbf022c6",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "90d5ec13c7226d2e9e3ba96b754607cdd272673c42ba65f3bc3c20befbf022c7",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "9f25bfa42c657dc7c9cd2efe7cd28a97f4bb8fa240d1ee0659aa7bf33cbe6efe",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "9f25bfa42c657dc7c9cd2efe7cd28a97f4bb8fa240d1ee0659aa7bf33cbe6eff",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "a26e4af2782c0ebfbe03e765ee791b2f42d3b347e2cc15ae0df792a366dfacc0",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "a26e4af2782c0ebfbe03e765ee791b2f42d3b347e2cc15ae0df792a366dfacc1",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "d00d75bee6f8c3a42f6a4635d87dac3485b6ee1792c51f4d1031e5e3b0ae4e0c",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "d00d75bee6f8c3a42f6a4635d87dac3485b6ee1792c51f4d1031e5e3b0ae4e0d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  }
];

export class ThunderExchangeInterface extends Interface {
  constructor() {
    super(abi);
  }

  declare functions: {
    cancel_order: FunctionFragment;
    execute_order: FunctionFragment;
    get_asset_manager: FunctionFragment;
    get_execution_manager: FunctionFragment;
    get_pool: FunctionFragment;
    get_protocol_fee_recipient: FunctionFragment;
    get_royalty_manager: FunctionFragment;
    get_transfer_selector: FunctionFragment;
    initialize: FunctionFragment;
    owner: FunctionFragment;
    place_order: FunctionFragment;
    renounce_ownership: FunctionFragment;
    set_asset_manager: FunctionFragment;
    set_execution_manager: FunctionFragment;
    set_pool: FunctionFragment;
    set_protocol_fee_recipient: FunctionFragment;
    set_royalty_manager: FunctionFragment;
    set_transfer_selector: FunctionFragment;
    transfer_ownership: FunctionFragment;
    update_order: FunctionFragment;
  };
}

export class ThunderExchange extends Contract {
  static readonly abi = abi;
  static readonly storageSlots = storageSlots;

  declare interface: ThunderExchangeInterface;
  declare functions: {
    cancel_order: InvokeFunction<[strategy: ContractIdInput, nonce: BigNumberish, side: SideInput], void>;
    execute_order: InvokeFunction<[order: TakerOrderInput], void>;
    get_asset_manager: InvokeFunction<[], ContractIdOutput>;
    get_execution_manager: InvokeFunction<[], ContractIdOutput>;
    get_pool: InvokeFunction<[], ContractIdOutput>;
    get_protocol_fee_recipient: InvokeFunction<[], IdentityOutput>;
    get_royalty_manager: InvokeFunction<[], ContractIdOutput>;
    get_transfer_selector: InvokeFunction<[], ContractIdOutput>;
    initialize: InvokeFunction<[], void>;
    owner: InvokeFunction<[], Option<IdentityOutput>>;
    place_order: InvokeFunction<[order: MakerOrderInput], void>;
    renounce_ownership: InvokeFunction<[], void>;
    set_asset_manager: InvokeFunction<[asset_manager: ContractIdInput], void>;
    set_execution_manager: InvokeFunction<[execution_manager: ContractIdInput], void>;
    set_pool: InvokeFunction<[pool: ContractIdInput], void>;
    set_protocol_fee_recipient: InvokeFunction<[protocol_fee_recipient: IdentityInput], void>;
    set_royalty_manager: InvokeFunction<[royalty_manager: ContractIdInput], void>;
    set_transfer_selector: InvokeFunction<[transfer_selector: ContractIdInput], void>;
    transfer_ownership: InvokeFunction<[new_owner: IdentityInput], void>;
    update_order: InvokeFunction<[order: MakerOrderInput], void>;
  };

  constructor(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider,
  ) {
    super(id, abi, accountOrProvider);
  }
}
